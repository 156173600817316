.Cloud {
  text-align: center;
  min-height: 80vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cloud-header {
  background-color: #189AB4;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 4vmin);
  color: white;
  font-weight: bold;
  padding: 20px;
  position: relative;
}

.cloud-link {
  color: #61dafb;
}

@keyframes cloud-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-container {
  position: relative;
  width: 100%;
}

.sphere-right {
  position: relative;
  top: 400px;
  width: 30%;
  height: auto;
  right: 400px;
}

.cloud-1 {
  position: relative;
  width: 20%;
  height: auto;
  top: -600px;
  right: 300px;
  fill: beige !important;
  opacity: 0.5;
}

.cloud-2 {
  position: relative;
  width: 20%;
  height: auto;
  left: 40%;
  top: -200px;
  fill: beige !important;
  opacity: 0.8;
}

.star {
  position: relative;
}

.star-1 {
  top: 20%;
  left: 10%;
  width: 20%;
  height: auto;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {

  .cloud-header {
    min-height: 60vh;  /* Adjust this value */
    background-color: #189AB4; 
}

  .sphere-right {
      top: 200px;
      width: 50%;
      right: 200px;
  }

  .cloud-1 {
    display: none;
  }

  .cloud-2 {
      left: 20%;
      top: -20px;
      width: 30%;

  }

  .star-1 {
      top: 10%;
      left: 5%;
      width: 30%;
  }
}
