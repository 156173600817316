.ourValue {
    background: #ffff;
    background-image:"../../../Assets/connected.png" ;
    padding: 2rem;
}
.ourValueDetails {
    background-color: #ea8685;
    border-radius: 0.25rem;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 10%);
    padding: 1.3rem 1rem 1rem;
    transition: all 1s;
    text-align: center;
}
.valueIcon {
    padding: 0.4rem 0.8rem;
    font-size: 3rem;
    color: #fff;
    border-radius: 0.25rem
} 
.valueIcon1 {
    background: #f5f5f5;
    box-shadow: 0 2px 15px #05445E;
}
.valueIcon2 {
    box-shadow: 0 2px 15px #05445E;
    background-color: #f5f5f5;
}
.valueIcon3 {
    box-shadow: 0 2px 15px #05445E;
    background-color: #f5f5f5;
}
.valueIcon4 {
    box-shadow: 0 2px 15px rgb(255 88 110 / 50%);
    background-color: #f5f5f5;
}
.ourValueNumber {
    font-weight: 700;
    font-size: 2.4rem;
    color: #ffff;
}
.ourValueTitle {
    color: #ffff;
    margin: 1.5rem 0 1rem 0;
    font-weight: 500;
    font-size: 1.2rem;
}

@media (max-width: 981px) {
    .ourValueDetails{
        margin-top: 1rem;
    }
}