#contact {
    padding: 7rem 0 6rem;
    overflow: hidden;
    min-height: 80vh;
    background:linear-gradient(0deg, rgba(20, 19, 20, 0.3), rgba(139, 137, 138, 0.3)), url("../../../Assets/japan-dark-evening.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.sectionTitle {
    margin: 0.5rem 0 3rem;
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-bottom: 0.6rem;
}
.sectionTitle::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100px;
    border-radius: 70px;
    height: 4px;
    background: #ea8685;
}

.contactForm {
    padding: 1rem;
}
.contactForm input,
.contactForm textarea {
    width: 100%;
    margin: 0.5rem 0;
    padding: 1rem 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid #bebebe;
    color: #000;
}
.contactForm input:focus,
.contactForm textarea:focus {
    outline: none !important;
    border: 1px solid #7054F2;
    background-color: rgb(243, 240, 255);
}
.contactForm textarea {
    height: 120px;
}

.title {
    position: relative;
    font-size: 3rem;
   text-align: center;
   color: #ffff;
}

.subtitle{
    position: relative;
    font-size: 1.5rem;
   text-align: left;
   color: #ffff;
   padding-top: 5%;
   padding-bottom: 5%;
}


.contactBtn {
    background: #ea8685;
    padding: 18px 35px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
}
.contactBtn:hover {
    color: #fff;
    background-color: #55cbcd;
}

@media (max-width: 768px) {
    .title, .subtitle, .sectionTitle {
        padding: 1rem;  /* Adjust as needed */
    }
.contactBtn{
              
    width: 50%;              /* Takes the full width of its container */
    margin-left: 1rem;  
    padding: 1rem;
}
}

