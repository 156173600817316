.Port {
    text-align: center;
  }

  
  .port-header {
    min-height: 80vh;
    background:linear-gradient(0deg, rgba(20, 19, 20, 0.3), rgba(139, 137, 138, 0.3)), url("../../../Assets/street-daytime.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 60px;
    color: white;
    font-weight: bold;
    padding: 20px;
  }