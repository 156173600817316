.header {
    height: 80vh;
    background-image: linear-gradient(#05445E, #189AB4);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    overflow: hidden;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.titleArea {
    padding: 1rem;
}
.miniTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #f5f5f5;
    text-transform: uppercase;
}
.headerTitle{
    margin-bottom: 10px;
    font-size: 60px;
    font-weight: 800;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif!important;
}

.headerContent {
    font-size: 24px;
    line-height: 1.5;
    color: #f5f5f5;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin: 0.5rem 0 1rem
}
.branBtn {
    background: #ea8685;
    padding: 18px 35px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
}
.branBtn:hover {
    color: #fff;
    background-color: #55cbcd;
}
.headerHighlight {
    color: #ea8685;
}

@media (max-width: 981px) {
    .header{
        overflow: visible;
        height: 100%;
    }
    .headerTitle{
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: 700;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif!important;
    }
}